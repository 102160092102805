<div [class.p-4]="!inCheckout">
  <div class="alert alert-warning d-md-flex justify-content-between" role="alert" *ngIf="cartItem.status != 'Available'">
    <div>{{ cartItem.inventoryItem.make }} {{ cartItem.inventoryItem.model }} is not available.</div>
    <u class="text-underline" (click)="removeFromCart()" *ngIf="!onMachineDetails">Remove</u>
  </div>
  <div class="d-flex fs-18">
    <div>Summary</div>
    <div class="ml-auto">{{ rateLabel }}</div>
  </div>
  <div class="bb-light mb-3 mt-1"></div>
  <!-- machine -->
  <div class="d-flex align-items-center">
    <app-thumbnail-with-x
      *ngIf="!onMachineDetails"
      [height]="inCheckout ? '125px' : '60px'"
      [width]="inCheckout ? '125px' : '60px'"
      borderRadius="5px"
      [imgSrc]="cartItem.imageThumbnail"
      [hideX]="!inCheckout"
      (xClicked)="confirmRemoveItem()"
    ></app-thumbnail-with-x>
    <div [class.ml-2]="!onMachineDetails" style="line-height: 26px">
      <ng-container *ngIf="!onMachineDetails">
        <div class="teko fs-26" style="font-weight: 500">{{ cartItem.inventoryItem.make }} {{ cartItem.inventoryItem.model }}</div>
        <div class="font-weight-light">{{ cartItem.inventoryItem.year }} {{ cartItem.inventoryItem.primaryType }}</div>
      </ng-container>

      <ng-container *ngIf="inCheckout">
        <ng-container *ngIf="isEstimate">
          <div class="bold">Rental Rate</div>
        </ng-container>
        <ng-container *ngIf="!isEstimate">
          <div class="font-weight-light">{{ cartItem.beginDate | date: "mediumDate" }}</div>
          <div class="font-weight-light">{{ cartItem.formattedDuration }}</div>
        </ng-container>
      </ng-container>
    </div>
    <div class="ml-auto">
      <div class="fs-20 bold" *ngIf="!machineRentBeforeDiscount">{{ rateSummary?.Rent | currency: "USD":"symbol":"1.0-0" }}</div>
      <div class="fs-20 bold" *ngIf="machineRentBeforeDiscount">{{ machineRentBeforeDiscount | currency: "USD":"symbol":"1.0-0" }}</div>
      <button *ngIf="inCheckout && !isEstimate && !onMachineDetails" class="float-right cursor-pointer ml-auto btn btn-outline-success" (click)="editClick()">
        Edit
      </button>
    </div>
  </div>
  <div class="bb-light my-3"></div>
  <!-- attachments -->
  <div *ngIf="cartItem.attachments.length">
    <p>Attachments</p>
    <div class="alert alert-warning" role="alert" *ngIf="attachmentsUnavailable?.length">
      <div class="d-flex justify-content-between">
        <div>The following attachments are not available:</div>
        <u class="text-underline d-none d-xl-block" (click)="removeAttachmentFromCartItem(attachment)" *ngIf="!onMachineDetails">Remove attachments</u>
      </div>
      <ul>
        <li *ngFor="let attachment of attachmentsUnavailable">{{ attachment }}</li>
      </ul>
      <div class="d-flex d-xl-none">
        <u class="ml-auto" (click)="removeAttachmentFromCartItem(attachment)" *ngIf="!onMachineDetails">Remove attachments</u>
      </div>
    </div>
    <ng-container *ngFor="let attachment of cartItem.attachments">
      <div class="d-flex mb-1">
        <div>
          <div class="font-weight-light">{{ attachment.title }}</div>
          <div class="font-weight-light mr-5 fs-14">{{ attachment.description }}</div>
        </div>
        <div class="ml-auto">{{ attachment[attachmentRateProperty] | currency: "USD":"symbol":"1.0-0" }}</div>
      </div>
    </ng-container>
    <div class="bb-light my-3"></div>
  </div>
  <!-- transportation -->
  <div class="d-flex">
    <div class="mr-2"><u class="cursor-pointer" (click)="transportationToolTip.open()">Transportation</u></div>
    <app-questionmark-tooltip
      [displayLearnMore]="false"
      text="Rubbl supports both short and long hauls across state lines. Once an order is placed you will receive a quote within 8 hours."
      #transportationToolTip
    >
    </app-questionmark-tooltip>
    <div class="ml-auto">-</div>
  </div>
  <div class="bb-light my-3"></div>
  <!-- services -->
  <div class="d-flex">
    <div><u class="cursor-pointer" (click)="servicesTooltip.open()">Rubbl Managed Services</u></div>
    <app-questionmark-tooltip
      class="ml-2"
      [displayLearnMore]="false"
      text="A fee of 5% of your rental rate. Includes damage coverage, preventative maintenance, transportation logistics, GPS tracking, and repair coordination. Minimum $149."
      #servicesTooltip
    >
    </app-questionmark-tooltip>
    <div class="ml-auto">{{ rateSummary?.PremiumServices | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
  <!-- discount -->
  <ng-container>
    <div class="bb-light my-3"></div>
    <div class="d-flex">
      <div><u class="cursor-pointer" (click)="discountTooltip.open()">Discount</u></div>
      <app-questionmark-tooltip
        class="ml-2"
        mobilePlacement="right"
        [displayLearnMore]="false"
        text="A discount is applied if your rental duration is longer than 3 months."
        #discountTooltip
      >
      </app-questionmark-tooltip>
      <div class="ml-auto text-primary">({{ discount | currency: "USD":"symbol":"1.0-0" }})</div>
    </div>
  </ng-container>
  <!-- Taxes -->
  <ng-container>
    <div class="bb-light my-3"></div>
    <div class="d-flex">
      <div><u class="cursor-pointer" (click)="salestaxTooltip.open()">Taxes</u></div>
      <app-questionmark-tooltip
        class="ml-2"
        mobilePlacement="right"
        [displayLearnMore]="false"
        text="Sales tax calculations take into account both equipment origin and destination. Sales tax is calculated during checkout."
        #salestaxTooltip
      >
      </app-questionmark-tooltip>
      <div class="ml-auto" *ngIf="rateSummary?.Tax != null">{{ rateSummary?.Tax | currency: "USD":"symbol":"1.0-0" }}</div>
      <div class="ml-auto" *ngIf="rateSummary?.Tax == null">-</div>
    </div>
  </ng-container>
  <div class="bb-light my-3"></div>
  <div class="d-flex fs-20">
    <div>Total</div>
    <div class="ml-auto">{{ total | currency: "USD":"symbol":"1.0-0" }}</div>
  </div>
</div>
