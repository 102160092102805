<div class="search-suggestions-container" [ngClass]="customClass" *ngIf="showSearchSuggestions" [@fadeInOut]>
  <div class="suggest-title supporting-text text-grey">Suggested</div>
  <ul class="suggestion-tray list-unstyled">
    <li class="suggested-term" *ngFor="let searchSuggestion of searchSuggestions" (mousedown)="selectSuggestedTerm(searchSuggestion)">
      <a>
        <span class="suggested-term-name" [innerHtml]="searchSuggestion.displayText"> </span>
      </a>
    </li>
  </ul>
</div>
