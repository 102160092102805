<div class="card border-0 mb-5" rt="machine-card" #card>
  <div class="card-image" [class.show-extra-info]="showExtraInfo">
    <swiper [config]="imageSwipeConfig" *ngIf="machine.thumbnails?.length > 0">
      <div *ngFor="let image of machine.thumbnails | slice: 0:3">
        <a
          rt="machine-card-img"
          [routerLink]="[machineDetailsUrl]"
          [queryParams]="{ id: machine.id }"
          [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
          [properties]="{ inventoryId: machine.id }"
          [state]="filteredAttachments"
        >
          <img class="card-img-top" *ngIf="machine.thumbnails.length == 1" [class.blur]="showExtraInfo" [src]="baseImagePath + image" />
          <img class="card-img-top swiper-lazy" *ngIf="machine.thumbnails.length != 1" [class.blur]="showExtraInfo" [attr.data-src]="baseImagePath + image" />
        </a>
      </div>
    </swiper>

    <ng-container *ngIf="machine.thumbnails?.length == 0">
      <a
        rt="machine-card-img"
        [routerLink]="[machineDetailsUrl]"
        [queryParams]="{ id: machine.id }"
        [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
        [properties]="{ inventoryId: machine.id }"
        [state]="filteredAttachments"
      >
        <img class="card-img-top" src="https://company.rubbl.com/pubweb/marketplace/images/images/machine-no-photos.png" />
      </a>
    </ng-container>

    <div class="info-icon-main-container cursor-pointer" *ngIf="!showExtraInfo" (click)="showExtraInfoclick()">
      <div class="icon-info-container">
        <i class="icon-info text-white fs-26"></i>
      </div>
    </div>

    <div class="top-owner-container" [class.extra-blur]="showExtraInfo" *ngIf="machine.isTopOwner">
      <common-top-owner [showTopOwner]="machine.isTopOwner"></common-top-owner>
    </div>
    <div
      class="machine-in-cart text-white px-3 py-1 font-weight-light w-100 d-flex align-items-center"
      style="z-index: 2"
      [class.blur]="showExtraInfo"
      [class.cursor-pointer]="hasStockPhotos && !machineInCart"
      *ngIf="machineInCart || hasStockPhotos"
      (click)="hasStockPhotos && !machineInCart && showStockPhotoInfo()"
    >
      <ng-container *ngIf="machineInCart"><i class="icon-checkmark mr-2"></i> Added to cart</ng-container>
      <div class="d-flex align-items-center cursor-pointer" *ngIf="hasStockPhotos && !machineInCart"><i class="icon-alert mr-2"></i> Stock photo</div>
    </div>

    <div class="bottom-right-container" [class.blur]="showExtraInfo">
      <div class="left-triangle"></div>
      <div class="price-container text-center" [class.blur]="showExtraInfo">
        <p class="price teko">${{ machine.rentalRate | thousandSuffix: 1 }}</p>
        <p class="per-month-text">PER MONTH</p>
      </div>
    </div>
  </div>
  <!-- start of extra info -->
  <div class="extra-info br-5 pt-3 t px-3 text-white w-100" [@fadeInOut] [style.height]="extraInfoHeight" *ngIf="showExtraInfo">
    <div class="info-icon-main-container cursor-pointer" (click)="hideExtraInfo()">
      <div class="icon-info-container">
        <i class="icon-cancel text-white fs-26"></i>
      </div>
    </div>
    <swiper [config]="extraInfoConfig" [(index)]="extraInfoIndex">
      <!-- first slide -->
      <div *ngIf="fixedAttachments.length || nonFixedAttachments.length">
        <ng-container *ngIf="fixedAttachments.length">
          <div class="fs-18 mb-1">Originally Equipped</div>
          <span *ngFor="let attachment of fixedAttachments" class="badge badge-pill fs-11 p-1 font-weight-light mr-2 mb-1 badge-outline-secondary-overlay">{{
            attachment.displayName
          }}</span>
        </ng-container>
        <ng-container *ngIf="nonFixedAttachments.length">
          <div class="fs-18 mb-1" [class.mt-2]="fixedAttachments.length">Attachments Available</div>
          <span *ngFor="let attachment of nonFixedAttachments" class="badge badge-pill fs-11 p-1 font-weight-light mr-2 mb-1 badge-outline-secondary-overlay">{{
            attachment.displayName
          }}</span>
        </ng-container>
      </div>
      <!-- second slide -->
      <div>
        <div class="fs-18 mb-3">Pricing Options</div>
        <p class="fs-14 font-weight-light">The following pricing options are available for this equipment.</p>
        <div class="d-flex justify-content-around font-weight-light mt-1">
          <div class="text-center" *ngFor="let rateSchedule of machine.rateSchedules | slice: 1; last as isLast; index as i">
            <p class="mb-0" *ngIf="rateSchedule.discount == 0">${{ rateSchedule.cost | thousandSuffix: 1 }}</p>
            <p class="mb-0" *ngIf="rateSchedule.discount != 0">${{ rateSchedule.cost / (rateSchedule.numDays / 28) | thousandSuffix: 1 }}</p>

            <ng-container [ngSwitch]="i">
              <p class="text-white mb-0 font-weight-light fs-12" *ngSwitchCase="0">Weekly</p>
              <p class="text-white mb-0 font-weight-light fs-12" *ngSwitchCase="1">Monthly</p>
              <p class="text-white mb-0 font-weight-light fs-12" *ngSwitchCase="2">3+ Mos</p>
              <p class="text-white mb-0 font-weight-light fs-12" *ngSwitchCase="3">6+ Mos</p>
            </ng-container>
            <span class="badge badge-pill badge-success p-1 font-weight-light" *ngIf="rateSchedule.discount != 0">{{ rateSchedule.discountPercent }}% off</span>
          </div>
        </div>
      </div>
      <!-- third slide -->
      <div *ngIf="hasStockPhotos">
        <div class="fs-18 mb-3">Stock photo</div>
        <p class="fs-14 font-weight-light">This listing contains a stock photo. All other information, including specs and pricing, is accurate.</p>
        <p class="fs-14 font-weight-light">Photos will be made available on order placement. Order cancellations are always free.</p>
        <p class="fs-14 font-weight-light">Call us at (866) 455-8531 for any questions.</p>
      </div>
      <!-- fourth slide -->
      <div class="px-2" *ngIf="machine.rpoEnabled">
        <common-machine-rpo-table [machine]="machine" fontClass="fs-14"></common-machine-rpo-table>
      </div>
    </swiper>
  </div>
  <!-- end of extra info -->
  <div class="text-info"></div>
  <div class="px-2" [class.blur]="showExtraInfo">
    <div class="d-flex mt-3 align-items-baseline main-info">
      <div class="main-info">
        <p class="teko machine-title">{{ machine.make }} {{ machine.model }}</p>
        <p class="font-weight-light mb-short">{{ machine.year }} {{ machine.primaryType }}</p>
        <p class="font-weight-light fs-12 mb-short">
          <ng-container *ngIf="machine.usage != 0"
            >{{ machine.usage | number: "1.0-0" }} {{ machine.usageAbbreviation || "hrs" }} <span class="mx-1">|</span></ng-container
          >

          {{ machine?.location | location: "cityState":false }}
          <ng-container *ngIf="machine.distanceFromUser">
            <span class="mx-1 d-none d-md-inline">|</span>
            <span class="d-none d-md-inline">{{ machine.distanceFromUser }} mls away</span>
          </ng-container>
        </p>
        <p class="d-md-none font-weight-light fs-12" *ngIf="machine.distanceFromUser">{{ machine.distanceFromUser }} mls away</p>
      </div>
      <div class="ml-auto font-weight-light">
        <div class="d-flex flex-column align-items-end">
          <span class="font-weight-light mb-2 text-green cursor-pointer fs-14" (click)="showPricingOptions()"><u>Pricing Options</u></span>
          <span class="badge badge-success p-1 font-weight-light d-flex cursor-pointer" (click)="showRpoInfo()" *ngIf="machine.rpoEnabled"
            ><i class="icon-info mr-1"></i> RPO Available</span
          >
        </div>
      </div>
    </div>
    <div>
      <ng-container *ngIf="fixedAttachments.length && !availableAndSelected.length">
        <div class="fs-12 bold">Originally Equipped</div>
        <span
          *ngFor="let attachment of fixedAttachments"
          class="badge badge-pill fs-12 p-1 font-weight-light mr-2 mb-1"
          [ngClass]="{ 'badge-secondary px-2': attachment.selected, 'badge-outline-secondary': !attachment.selected }"
          ><i class="icon-checkmark mr-2" *ngIf="attachment.selected" style="color: #f0b823"></i>{{ attachment.displayName }}</span
        >
        <span
          class="p-1 font-weight-light mr-2 mb-1 text-green cursor-pointer d-inline-flex align-items-center fs-14"
          *ngIf="nonFixedAttachments.length"
          (click)="showExtraInfoclick()"
          ><u>Show All</u></span
        >
      </ng-container>
      <!-- auto attachments -->
      <ng-container *ngIf="!fixedAttachments.length && !availableAndSelected.length && nonFixedAttachments.length">
        <span class="font-weight-light text-green cursor-pointer fs-14" (click)="showExtraInfoclick(0)"><u>Attachments Available</u></span>
      </ng-container>
      <ng-container *ngIf="availableAndSelected.length">
        <div class="fs-12 bold">Available and Selected</div>
        <span
          *ngFor="let attachment of availableAndSelected"
          class="badge badge-pill fs-12 p-1 font-weight-light mr-2 mb-1 px-2"
          [ngClass]="{ 'badge-secondary': attachment.selected, 'badge-outline-secondary': !attachment.selected }"
          ><i class="icon-checkmark mr-2" *ngIf="attachment.selected" style="color: #f0b823"></i>{{ attachment.displayName }}</span
        >
        <span class="p-1 font-weight-light mr-2 mb-1 text-green cursor-pointer d-inline-flex align-items-center fs-14" (click)="showExtraInfoclick()"
          ><u>Show All</u></span
        >
      </ng-container>
    </div>

    <div class="d-flex justify-content-center mt-2">
      <a
        rt="machine-card-view-details"
        class="btn btn-outline-dark bg-white mr-1 text-dark w-50"
        [routerLink]="[machineDetailsUrl]"
        [queryParams]="{ id: machine.id }"
        [commonEventTracker]="MarketTrackingEvents.SearchResult.Click"
        [properties]="{ inventoryId: machine.id }"
        [state]="filteredAttachments"
        >View Details
      </a>
      <button class="btn btn-secondary btn-add-to-cart ml-1 w-50" (click)="addToCart()">
        {{ machineInCart ? "Edit" : "Add to Cart" }}
      </button>
    </div>
  </div>
</div>
