import { ChangeDetectionStrategy } from "@angular/core";
import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-machine-summary",
  templateUrl: "./machine-summary.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./machine-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineSummaryComponent {
  @Input() machine: any;
  @Input() numberOfReviews: number;

  constructor() {}
}
