import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentConfig, BaseApiService, AuthenticationService, SearchResult, SearchModel, Location, LocationPipe } from "../../core/core";
import { InventoryItem, InventoryReview, Specification, Attachment } from "../inventory";
import { AssociatedItem } from "../models/associated-item.model";
import { AttachmentViewModel } from "../models/atachment-view-model.model";
import { Device } from "../models/device.model";
import { map } from "rxjs/operators";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class InventoryService extends BaseApiService<InventoryItem> {
  constructor(httpClient: HttpClient, authenticationService: AuthenticationService, private locationPipe: LocationPipe) {
    super(httpClient, environment.assetsApiDomain, "/api/assets/v2/inventory", true, authenticationService);
  }

  create(model: any): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post<any>(`${this.baseUrl}${this.endpoint}`, model, { headers });
  }

  getWithPartitionKey(id: string, partitionKey: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${id}/details?partitionKey=${partitionKey}`, { headers });
  }

  update(id: string, model: any): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put<any>(`${this.baseUrl}${this.endpoint}/${id}/details?partitionKey=${model.accountId}`, model, { headers });
  }

  getShippingDimensions(id: string, partitionKey: string) {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${id}/shipping?accountId=${partitionKey}`, { headers });
  }

  updateShippingDimensions(shippingDimensions: any) {
    const headers = this.getAuth();
    return this.httpClient.put<any>(
      `${this.baseUrl}${this.endpoint}/${shippingDimensions.inventoryItemId}/shipping?accountId=${shippingDimensions.accountId}`,
      shippingDimensions,
      { headers },
    );
  }

  getImages(inventoryId: string, accountId: string, orderByCover: boolean = false) {
    const headers = this.getAuth();
    let params = new HttpParams();
    params = params.append("accountId", accountId);
    params = params.append("orderByCover", orderByCover.toString());

    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/image`, { headers, params });
  }

  uploadImage(inventoryId: string, accountId: string, position: number, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${inventoryId}/image?accountId=${accountId}&position=${position}`, uploadData, { headers });
  }

  replaceImage(inventoryId: string, accountId: string, position: number, selectedFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", selectedFile);

    const headers = this.getAuth();
    return this.httpClient.put(`${this.baseUrl}${this.endpoint}/${inventoryId}/image?accountId=${accountId}&position=${position}`, uploadData, { headers });
  }

  updateCoverImage(inventoryId: string, accountId: string, position: number) {
    const headers = this.getAuth();
    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/cover?accountId=${accountId}&position=${position}`, {}, { headers });
  }

  deleteImage(inventoryId: string, accountId: string, position: number) {
    const headers = this.getAuth();
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/${position}?accountId=${accountId}`, { headers });
  }

  getReviews(machineId: string): Observable<SearchResult<InventoryReview>> {
    const searchModel = new SearchModel();
    searchModel["hasComments"] = true;
    return this.httpClient.get<SearchResult<InventoryReview>>(`${this.baseUrl}${this.endpoint}/${machineId}/review`, { params: searchModel as any });
  }

  getEquipmentTypeInfo(): Observable<any> {
    return this.httpClient.get<SearchResult<InventoryReview>>(`${this.baseUrl}/api/assets/v1/config/public/equipmenttype`);
  }

  getAttachments(inventoryId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment`, { headers });
  }

  addAttachment(inventoryId: string, item: AssociatedItem): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.post(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment`, item, { headers });
  }

  updateAttachment(inventoryId: string, attachmentAssociationId: string, item: AttachmentViewModel): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${attachmentAssociationId}`, item, { headers });
  }

  deleteAttachment(inventoryId: string, attachmentAssociationId: string, accountId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.delete(`${this.baseUrl}${this.endpoint}/${inventoryId}/attachment/${attachmentAssociationId}?accountId=${accountId}`, { headers });
  }

  getTelematics(inventoryId: string): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/telematics`, { headers });
  }

  setTelematics(inventoryId: string, device: Device): Observable<any> {
    const headers = this.getAuth();
    return this.httpClient.put<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/telematics`, device, { headers });
  }

  getLocation(inventoryId: string): Observable<Location> {
    const headers = this.getAuth();
    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/location`, { headers });
  }

  downloadAllPhotos(inventoryId: string) {
    const headers = this.getAuth();

    const requestOptions: Object = {
      headers,
      responseType: "arraybuffer",
    };

    return this.httpClient.get<any>(`${this.baseUrl}${this.endpoint}/${inventoryId}/image/archive`, requestOptions).pipe(
      map(result => {
        let blob = new Blob([result], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      }),
    );
  }

  getFormattedSpecifications(specifications: Specification[] | any[] = []): string {
    const specNamesAndValues = specifications["flatMap"](d => d.items).map(d => {
      return `${d.name}: ${d.value}`;
    });
    return specNamesAndValues.join(", ");
  }

  machineDetailsUrl(inventoryItem: InventoryItem, includeId: boolean = true): string {
    let seourl = `/equipment-rental/${this.locationPipe.transform(inventoryItem.location, "cityState", true).replace(", ", "-")}/${inventoryItem.primaryType}/${
      inventoryItem.make
    }/${inventoryItem.model}`;

    if (includeId) {
      seourl += `?id=${inventoryItem.id}`;
    }

    return seourl;
  }
}
