import { ApplicationRef, Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { EnvironmentConfig, LogService } from "@rubbl-core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { first, interval, concat } from "rxjs";

declare let environment: EnvironmentConfig;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "admin";

  testobject = {
    test: "",
  };
  constructor(private logService: LogService, private gtmService: GoogleTagManagerService, swUpdate: SwUpdate, appRef: ApplicationRef) {
    this.logService.info(AppComponent.name, "Starting Inspections app");

    swUpdate.available.subscribe((event) => {
      if (confirm("New version of Rubbl Inspect is available. Update now?")) {
        swUpdate.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  ngOnInit() {
    if (environment.production === "true") {
      this.gtmService.addGtmToDom();
    }

    try {
      navigator.storage.persist().then((granted) => {});
    } catch (e) {
      this.logService.info(AppComponent.name, "navigator.storage does not exist, all good here");
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (!isMobile && environment.production == "true") {
      alert("Rubbl Inspect is meant to be used on a mobile device, however you are still able to use it on any device.");
    }
  }
}
