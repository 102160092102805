import { Component } from "@angular/core";
import { INavigationItem, NavigationItem } from "@rubbl-layout";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
  navigationItems: NavigationItem[] = [
    { label: "Dashboard", route: "/" },
    { label: "Info", route: "/info" },
  ];

  accountNavigationItems: INavigationItem[] = [];

  constructor() {}
}
