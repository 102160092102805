<div class="p-4">
  <label>Select start date</label>
  <p class="font-weight-light mb-3">Must be within 14 days of today's date.</p>
  <div class="d-flex justify-content-center mb-3">
    <common-date-picker
      [useAsInput]="false"
      [numberOfDaysToBlockOff]="1"
      [maxNumberOfDays]="14"
      #datepicker
      (dateSelected)="onStartDateSelected($event)"
      [date]="startDate"
    ></common-date-picker>
  </div>
  <label>Select rental duration</label>
  <p class="font-weight-light">This is only an estimate. Keep the machine as long as needed.</p>
  <select class="form-control" [ngModel]="duration" (change)="onDurationSelected($event.target.value)">
    <option value="0">Select</option>
    <option value="7">1 week</option>
    <option value="14">2 weeks</option>
    <option value="28">1+ month</option>
    <option value="84">
      3+ months <span *ngIf="threeMonthDiscount">(Save {{ threeMonthDiscount | currency: "USD":"symbol":"1.0-0" }})</span>
    </option>
    <option value="168">
      6+ months <span *ngIf="sixMonthDiscount">(Save {{ sixMonthDiscount | currency: "USD":"symbol":"1.0-0" }})</span>
    </option>
  </select>
  <ng-container *ngIf="rpoEnabled">
    <div class="mt-4 mb-4"></div>
    <label>Are you interested in purchasing?</label>
    <div class="form-group">
      <div class="d-flex justify-content-between btn-group-toggle" data-toggle="buttons">
        <label class="btn btn-pass w-100 mr-3 py-2 d-flex align-items-center justify-content-center" [class.active]="rpoInterested === true">
          <input class="form-check-input" type="radio" [value]="true" (click)="rpoChange(true)" />
          <i class="icon-checkmark fs-24 mr-1"></i>
          Yes
        </label>

        <label class="btn btn-outline-secondary w-100 py-2 d-flex align-items-center justify-content-center" [class.active]="rpoInterested === false">
          <input class="form-check-input" type="radio" [value]="false" (click)="rpoChange(false)" />
          <i class="icon-close fs-18 mr-1"></i>
          No
        </label>
      </div>
    </div>
  </ng-container>
</div>
