import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CoreModule } from "../core/core.module";
import { AccountCardComponent } from "./components/account-card/account-card.component";

import { FormsModule } from "../forms/forms.module";
import { AccountSupportComponent } from "./components/account-support/account-support.component";
import { UserThumbnailComponent } from "./components/user-thumbnail/user-thumbnail.component";
import { UserDropdownComponent } from "./components/user-dropdown/user-dropdown.component";
import { ContactPipe } from "./pipes/contact-pipe";
import { TopOwnerComponent } from "./components/top-owner/top-owner.component";
import { UserAvatarComponent } from "./components/user-avatar/user-avatar.component";
import { AccountVerificationBannerComponent } from "./components/account-verification-banner/account-verification-banner.component";
import { OwnerCardComponent } from "./components/owner-card/owner-card.component";
import { RouterModule } from "@angular/router";
import { SwiperModule } from "ngx-swiper-wrapper";

@NgModule({
  declarations: [
    AccountCardComponent,
    AccountSupportComponent,
    UserThumbnailComponent,
    UserDropdownComponent,
    ContactPipe,
    TopOwnerComponent,
    UserAvatarComponent,
    AccountVerificationBannerComponent,
    OwnerCardComponent,
  ],
  imports: [CommonModule, FormsModule, CoreModule, RouterModule, SwiperModule],
  exports: [
    AccountCardComponent,
    AccountSupportComponent,
    UserThumbnailComponent,
    UserDropdownComponent,
    ContactPipe,
    TopOwnerComponent,
    UserAvatarComponent,
    AccountVerificationBannerComponent,
    OwnerCardComponent,
  ],
})
export class AccountModule {}
