import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { AzureSearchService, AzureSearchRequest, AzureSearchResult } from "../../azure-search/azure-search";
import { InventoryItem } from "../../inventory/inventory";

import { ActivatedRoute, Params, Router } from "@angular/router";
import { MapBounds } from "../models/facets/map-facet.model";
import { SearchRequest } from "../models/search-request.model";
import { LocationFacet } from "../models/facets/location-facet.model";
import { SeoRouteData, SeoService } from "../../core/core";

const PageSize = 10;
const IndexName = "machines";

@Injectable({
  providedIn: "root",
})
export class SearchResultsService {
  private azureSearchRequest = new AzureSearchRequest();
  private currentPageSize = PageSize;
  private totalInventoryItems;

  public readonly searchRequest$ = new Subject<SearchRequest>();
  searchRequest = new SearchRequest();

  public readonly searchResults$ = new Subject<AzureSearchResult<InventoryItem>>();
  private searchResults = new AzureSearchResult<InventoryItem>();

  ownerSearch: boolean = false;
  // if we want to reduce size of response. needs more testing
  // defaultSelectFields: string =
  //   "thumbnails,year,hours,usage,usageLabel,usageAbbreviation,enginePower,isTopOwner, purchasePrice, createdDateTime,status,make,model,primaryType,weight,rentalRate,typeDefinition,displayName,description,relatedAttachments,location,rateSchedules,account";

  constructor(private azureSearchService: AzureSearchService, private router: Router, private route: ActivatedRoute, private seoService: SeoService) {
    this.azureSearchRequest.top = PageSize;
    this.azureSearchRequest.facets = [
      "make, count:200, sort:count",
      "model, count:400, sort:count",
      "primaryType, count: 200, sort:value",
      "typeDefinition, count: 200, sort:count",
      "rpoEnabled",
      "weight,interval:1",
      "rating, interval:1",
      "hours,interval:100",
      "year, interval:1",
      "enginePower, interval: 1",
      "relatedAttachments/size, count: 500, sort:value",
      "relatedAttachments/typeDefinition, count: 400, sort:count",
    ];
    this.azureSearchRequest.searchFields = "make,model,primaryType,account/name,typeDefinition,displayName";
    this.azureSearchRequest.searchMode = "all";
  }

  search(searchTerm: string, location: LocationFacet, top: number = 10, newSearch: boolean = false, selectFields: string = null) {
    this.clearSearchResults();

    this.searchRequest.searchTerm = searchTerm;
    this.searchRequest.location = location;
    this.azureSearchRequest.select = selectFields;
    this.azureSearchRequest.search = searchTerm ? `${searchTerm} | ${searchTerm}* | "${searchTerm}"` : "";

    // if it is a new search, such as a user going straight to the search results page, or doing a search from the page, just filter for status equal available
    this.azureSearchRequest.filter = newSearch ? this.searchRequest.newsearchFilter : this.searchRequest.filter;
    this.azureSearchRequest.orderby = this.searchRequest.order;
    this.azureSearchRequest.top = top;

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      // if it is not a new search(consective search) then just finish the results, otherwise we need to process all the persistant facet logic
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;
      if (!newSearch || this.searchRequest.facetSelectedCount === 0) {
        this.searchFinished(result);
        return;
      }

      // the reason for this logic is because azure search index only includes facets for your search results, and not for the whole result set
      if (this.searchRequest.primaryType.selected) {
        this.primaryTypeSelected();
      } else if (this.searchRequest.make.selected) {
        this.makeSelected();
      } else {
        this.lastSearch();
      }
    });
  }

  primaryTypeSelected() {
    this.searchRequest.attachment.clear(); // always clear attachment

    // if type def is selected we need to clear it, otherwise it will show all type definitions
    if (this.searchRequest.typeDefinition.selected) {
      this.searchRequest.typeDefinition.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    // if make or model is selected we need to wipe them out and re apply the filter
    if (this.searchRequest.make.selected || this.searchRequest.model.selected) {
      this.searchRequest.make.clear();
      this.searchRequest.model.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;

      // if make is selected we need to wipe out model in the
      if (this.searchRequest.make.selected) {
        this.makeSelected();
        // if model is selected or type def selected we need to make one more god damn search
      } else if (this.searchRequest.model.selected || this.searchRequest.typeDefinition.selected) {
        this.lastSearch();
      } else {
        this.searchFinished(result);
      }
    });
  }

  makeSelected() {
    this.searchRequest.attachment.clear(); // always clear attachment

    // if the model is selected we need to wipe out and re apply the filter
    if (this.searchRequest.model.selected) {
      this.searchRequest.model.clear();
      this.azureSearchRequest.filter = this.searchRequest.filter;
    }

    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchRequest.initializeFacets(result.search.facets);
      this.azureSearchRequest.filter = this.searchRequest.filter;

      if (this.searchRequest.model.selected) {
        this.lastSearch();
      } else {
        this.searchFinished(result);
      }
    });
  }

  lastSearch() {
    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.searchFinished(result);
    });
  }

  searchFinished(result: AzureSearchResult<InventoryItem>) {
    this.totalInventoryItems = result.odata.count;
    this.modifyResults(result);
    this.searchResults = result;
    this.searchResults$.next(result);
    this.updateFacets(result.search.facets);
  }

  modifyResults(result: AzureSearchResult<InventoryItem>) {
    const userLongLat = this.searchRequest.location.getLatLong();
    for (let machine of result.value) {
      if (userLongLat) {
        machine.distanceFromUser = this.calculateDistance(userLongLat[0], userLongLat[1], machine.location.longitude, machine.location.latitude);
      }
    }
  }

  calculateDistance(lon1, lat1, lon2, lat2): number {
    const R = 6371; // Radius of the earth in km
    const dLat = this.toRad(lat2 - lat1); // Javascript functions in radians
    const dLon = this.toRad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const km = R * c; // Distance in km
    return Math.ceil(km * 0.621371); // distance in miles
  }

  toRad(coordinate) {
    return (coordinate * Math.PI) / 180;
  }

  nextPage() {
    if (this.totalInventoryItems < this.azureSearchRequest.skip + this.currentPageSize) {
      return;
    }

    this.azureSearchRequest.skip += this.currentPageSize;
    this.azureSearchService.search<InventoryItem>(this.azureSearchRequest, IndexName).subscribe((result: AzureSearchResult<InventoryItem>) => {
      this.totalInventoryItems = result.odata.count;
      this.modifyResults(result);
      const previousSearchResults = this.searchResults;
      Array.prototype.push.apply(previousSearchResults.value, result.value);
      this.searchResults$.next(previousSearchResults);
    });
  }

  filterResults() {
    // update params yo
    const queryParams: Params = this.searchRequest.toQueryParam();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  mapMoved(bounds: MapBounds) {
    this.searchRequest.map.mapBounds = bounds;
    this.azureSearchRequest.top = 1000;
    this.filterResults();
  }

  updateFacets(facets: any) {
    this.searchRequest.initializeFacets(facets);
    this.searchRequest$.next(this.searchRequest);
  }

  clearSearchResults() {
    window.scrollTo(0, 0);
    this.azureSearchRequest.skip = 0;

    this.searchResults = null;
    this.searchResults$.next(null);
  }

  resetSearchRequest() {
    this.searchRequest = new SearchRequest();
    this.searchRequest$.next(null);
  }

  updateMetaTags() {
    let searchTerm = this.searchRequest.searchTerm || "Heavy Equipment";
    searchTerm = searchTerm[0].toUpperCase() + searchTerm.slice(1);
    const city = this.searchRequest.location.toCityState();

    const firstCityString = city ? `now in and near ${city}` : "near you";
    const secondCityString = city ? `in ${city}` : "near you";

    const description = `${searchTerm} rentals available ${firstCityString}. Find construction rentals ${secondCityString} and surrounding areas online. Rent the best ${searchTerm} for your project today. Explore prices and our real-time inventory.`;

    const cityKeyword = city ? `${city},` : "";
    this.seoService.setSeo({
      title: `${searchTerm} rentals available ${firstCityString} | Rubbl`,
      description,
      keywords: `rubbl,${searchTerm},${cityKeyword}heavy,equipment,construction,rental,rent,dirt,moving,dozer,excavator,caterpillar,near,me`,
      image: "https://company.rubbl.com/pubweb/marketplace/images/logos/og-rubbl-dark.jpg",
    } as SeoRouteData);
  }
}
