import { Injectable } from "@angular/core";
import { EnvironmentConfig } from "../core";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  get(key: any, useSessionStorage: boolean = false) {
    let storageToUse = localStorage;
    if (useSessionStorage) {
      storageToUse = sessionStorage;
    }

    const storageValue = storageToUse.getItem(environment.clientId + "." + key);
    return storageValue ? JSON.parse(storageValue) : null;
  }

  set(key: any, value: any, useSessionStorage: boolean = false) {
    let storageToUse = localStorage;
    if (useSessionStorage) {
      storageToUse = sessionStorage;
    }

    const data = JSON.stringify(value);
    if (data) {
      storageToUse.setItem(environment.clientId + "." + key, data);
    }
  }

  clearAll() {
    localStorage.clear();
  }
}

export const LocalStorageKeys = {
  CartId: "cart-id",
  CheckoutTermsAccepted: "checkout-terms-accepted",
  InspectionWelcome: "welcome",
};
