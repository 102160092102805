import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from "@angular/forms";
import { DatePickerComponent } from "./components/date-picker/date-picker.component";

import { ButtonSpinnerComponent } from "./components/button-spinner/button-spinner.component";
import { RadioCardsComponent } from "./components/radio-cards/radio-cards.component";
import { QuestionmarkTooltipComponent } from "./components/questionmark-tooltip/questionmark-tooltip.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { RubblSharedModule } from "../rubbl-shared/rubbl-shared.module";
import { EditItemFooterComponent } from "./components/edit-item-footer/edit-item-footer.component";
import { MultiAddInputComponent } from "./components/multi-add-input/multi-add-input.component";
import { MultiDropdownComponent } from "./components/multi-dropdown/multi-dropdown.component";
import { InputCheckboxComponent } from "./components/input-checkbox/input-checkbox.component";
import { DateRangePickerComponent } from "./components/date-range-picker/date-range-picker.component";
import { JobDurationDropdownComponent } from "./components/job-duration-dropdown/job-duration-dropdown.component";
import { InputNumberStepperComponent } from "./components/input-number-stepper/input-number-stepper.component";
import { RadioButtonComponent } from "./components/radio-button/radio-button.component";
import { StatusLabelComponent } from "./components/status-label/status-label.component";
import { ValidationCardComponent } from "./components/validation-card/validation-card.component";
import { IconButtonComponent } from "./components/icon-button/icon-button.component";
import { IconButtonSecondaryComponent } from "./components/icon-button-secondary/icon-button-secondary.component";
import { IconInputComponent } from "./components/icon-input/icon-input.component";
import { StatusLabelDropdownComponent } from "./components/status-label-dropdown/status-label-dropdown.component";
import { MultipleInputListComponent } from "./components/multiple-input-list/multiple-input-list.component";
import { ImagePreviewUploadComponent } from "./components/image-preview-upload/image-preview-upload.component";
import { CheckboxCardsComponent } from "./components/checkbox-cards/checkbox-cards.component";
import { DisplayEntityHeaderComponent } from "./components/display-entity-header/display-entity-header.component";
import { NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropFileUploadComponent } from "./components/drag-drop-file-upload/drag-drop-file-upload.component";

@NgModule({
  declarations: [
    ButtonSpinnerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DropdownComponent,
    EditItemFooterComponent,
    InputCheckboxComponent,
    InputNumberStepperComponent,
    JobDurationDropdownComponent,
    MultiAddInputComponent,
    MultiDropdownComponent,
    QuestionmarkTooltipComponent,
    RadioButtonComponent,
    RadioCardsComponent,
    StatusLabelComponent,
    ValidationCardComponent,
    IconButtonComponent,
    IconButtonSecondaryComponent,
    IconInputComponent,
    StatusLabelDropdownComponent,
    MultipleInputListComponent,
    ImagePreviewUploadComponent,
    // VoiceToTextInputComponent,
    CheckboxCardsComponent,
    DisplayEntityHeaderComponent,
    DragDropFileUploadComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularFormsModule,
    RubblSharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    ClipboardModule,
  ],
  exports: [
    ButtonSpinnerComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DropdownComponent,
    EditItemFooterComponent,
    InputCheckboxComponent,
    InputNumberStepperComponent,
    JobDurationDropdownComponent,
    MultiAddInputComponent,
    MultiDropdownComponent,
    QuestionmarkTooltipComponent,
    RadioButtonComponent,
    RadioCardsComponent,
    StatusLabelComponent,
    ValidationCardComponent,
    IconButtonComponent,
    IconButtonSecondaryComponent,
    StatusLabelDropdownComponent,
    MultipleInputListComponent,
    ImagePreviewUploadComponent,
    // VoiceToTextInputComponent,
    CheckboxCardsComponent,
    DisplayEntityHeaderComponent,
    IconInputComponent,
    DragDropFileUploadComponent,
    ReactiveFormsModule,
    AngularFormsModule,
  ],
})
export class FormsModule {}
