<div rt="machine-summary">
  <div class="d-flex mb-1">
    <h4 class="section-title">{{ machine.year }} {{ machine.primaryType }}</h4>
  </div>

  <div class="d-flex">
    <h2 class="model-header">{{ machine.make }} {{ machine.model }}</h2>
  </div>

  <div class="d-flex mb-2">
    <span class="subhead-2 mr-2" *ngIf="machine.weight != null">{{ machine.weight | number: "1.0-0" }} lbs</span>
    <span class="text-light-grey" *ngIf="machine.weight != null && machine.enginePower != null">|</span>
    <span class="ml-2 subhead-2" *ngIf="machine.enginePower != null">{{ machine.enginePower | number: "1.0-0" }} HP</span>

    <div class="d-none d-md-block">
      <span class="text-light-grey">|</span>
      <span class="ml-2 subhead-2" *ngIf="machine.usage != null">{{ machine.usage | number: "1.0-0" }} {{ machine.usageLabel }}</span>
    </div>
  </div>

  <div class="d-flex d-md-none">
    <span class="subhead-2" *ngIf="machine.usage != null">{{ machine.usage | number: "1.0-0" }} {{ machine.usageLabel }}</span>
  </div>

  <div class="d-flex mb-2 mt-2" *ngIf="machine.location">
    <span class="location-label text-grey">{{ machine.location | location: "cityState":false }}</span>
  </div>

  <div class="d-flex mb-2"></div>
</div>
