import { Injectable } from "@angular/core";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

import { EnvironmentConfig } from "../models/environment-config.model";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { GoogleTagManagerService } from "angular-google-tag-manager";

declare var environment: EnvironmentConfig;

export enum LogLevels {
  Trace = "Trace",
  Debug = "Debug",
  Info = "Info",
  Warn = "Warn",
  Error = "Error",
}

@Injectable({
  providedIn: "root",
})
export class LogService {
  private currentLogLevel: LogLevels;
  appInsights: ApplicationInsights;

  constructor(private gaService: GoogleAnalyticsService, private gtmService: GoogleTagManagerService) {
    this.currentLogLevel = (environment["logLevel"] as LogLevels) || LogLevels.Debug;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsKey,
        enableAutoRouteTracking: true, // option to log all route changes
        correlationHeaderDomains: ["*.rubbl.com"],
      },
    });

    this.appInsights.loadAppInsights();

    this.appInsights.addTelemetryInitializer((envelope) => {
      const item = envelope.baseData;
      item.properties = item.properties || {};
      item.properties["ApplicationPlatform"] = "WEB";
      item.properties["ApplicationName"] = environment.clientId;
      envelope.tags["ai.cloud.role"] = environment.clientId;

      // since we are having app insights auto track the route it uses the window title for the event name
      // since our window title doesn't change right now, it will probably be dynamic in the future
      // lets just use the pathname
      if (envelope.baseType === "PageviewData") {
        item.name = window.location.pathname;
      }
    });

    this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  }

  setUser(userId: string, accountId: string) {
    this.appInsights.setAuthenticatedUserContext(userId, accountId);
    this.gaService.gtag("set", { user_id: userId }); // Set the user ID using signed-in user_id.
  }

  clearUser() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  trackEvent(name: string, properties?: { [key: string]: any }, sendToGtm: boolean = false) {
    this.appInsights.trackEvent({ name }, properties);

    if (sendToGtm) {
      this.gtmService.pushTag(properties);
    }
  }

  pushTagToGtm(properties?: { [key: string]: any }) {
    this.gtmService.pushTag(properties);
  }

  debug(componentOrServiceName: string, message: any) {
    if (this.currentLogLevel > LogLevels.Debug) {
      return;
    }

    console.log(`${componentOrServiceName}:`, message);

    this.appInsights.trackTrace({
      message: `${componentOrServiceName}: ${JSON.stringify(message)}`,
      severityLevel: SeverityLevel.Information,
    });
  }

  info(componentOrServiceName: string, message: any) {
    if (this.currentLogLevel > LogLevels.Info) {
      return;
    }

    console.log(`${componentOrServiceName}: ${JSON.stringify(message)}`);

    this.appInsights.trackTrace({
      message: `${componentOrServiceName}: ${JSON.stringify(message)}`,
      severityLevel: SeverityLevel.Information,
    });
  }

  warn(componentOrServiceName: string, message: any) {
    if (this.currentLogLevel > LogLevels.Warn) {
      return;
    }

    console.warn(`${componentOrServiceName}:`, message);
    this.appInsights.trackTrace({
      message: `${componentOrServiceName}: ${JSON.stringify(message)}`,
      severityLevel: SeverityLevel.Warning,
    });
  }

  error(componentOrServiceName: string, error: Error) {
    this.appInsights.trackException({ error });
    console.error(error);
  }
}
