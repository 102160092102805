import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Cart, CartItem, CartSearchModel } from "./models/cart.model";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  BaseApiService,
  AuthenticationService,
  LocalStorageService,
  LocalStorageKeys,
  SearchResult,
  EnvironmentConfig,
  ErrorsHandler,
  LogService,
  MarketTrackingEvents,
  DateTimeUtils,
} from "../core/core";
import { TransportationInfo, JobsiteContact } from "../transportation/transportation";
import { User } from "oidc-client";
import { tap } from "rxjs/operators";

declare var environment: EnvironmentConfig;

@Injectable({
  providedIn: "root",
})
export class CartService extends BaseApiService<Cart> {
  user: User;

  cart: Cart = new Cart();
  cartBSubject: BehaviorSubject<Cart> = new BehaviorSubject<Cart>(this.cart);

  constructor(
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private errorsHandler: ErrorsHandler,
    private logService: LogService,
  ) {
    super(httpClient, environment.ordersApiDomain, "/api/orders/v2/cart", true, authenticationService);

    if (environment.clientId !== "rubbl.website") {
      return;
    }

    this.init();
  }

  init() {
    this.authenticationService.getUser().then(user => {
      const cartIdFromLocalStorage = this.localStorageService.get(LocalStorageKeys.CartId);
      // if no user but there is a cart id in local storage, we need to get it from api
      if (!user) {
        if (cartIdFromLocalStorage) {
          this.getWithPartitionKey(cartIdFromLocalStorage, "00000000-0000-0000-0000-000000000000", "").subscribe(cart => this.updateSubscribers(cart));
        }
        return;
      }

      // user logged in! grab cart by user id
      this.user = user;
      this.getCartByUserId(user.profile.sub).subscribe(cartSearchResult => {
        // if we couldnt find cart on api and there is a local storage cart lets tie the user to this cart
        if (cartSearchResult.results.length === 0) {
          if (cartIdFromLocalStorage) {
            this.updateCartUser(cartIdFromLocalStorage, user.profile.sub, user.profile.account_id).subscribe(userCart => this.updateSubscribers(userCart));
            this.localStorageService.set(LocalStorageKeys.CartId, "");
          }
          return;
        }

        // we found a cart from the api
        const cart = cartSearchResult.results.pop();
        this.updateSubscribers(cart);

        // if there isnt a cart id in local storage do not merge the carts
        if (!cartIdFromLocalStorage) {
          return;
        }

        // there is a cart from the api and a cart id in local storage we need to merge the two carts
        this.getWithPartitionKey(cartIdFromLocalStorage, "00000000-0000-0000-0000-000000000000", "").subscribe(localStorageCart =>
          this.mergeCarts(localStorageCart, cart),
        );
      });
    });
  }

  mergeCarts(localStorageCart: Cart, apiCart: Cart) {
    // find cart items that do exist on the local storage cart, but do not exist on the api cart
    const cartItemsToAdd = [];
    for (const cartItem of localStorageCart.items) {
      if (!apiCart.items.some(d => d.inventoryItem.id === cartItem.inventoryItem.id)) {
        const cartItemToAdd = new CartItem(
          new Date(cartItem.beginDate),
          DateTimeUtils.calcMonthDurationFromDays(cartItem.duration),
          cartItem.inventoryItem,
          [],
          cartItem.rpoInterested,
        );
        cartItemsToAdd.push(cartItemToAdd);
      }
    }

    // add cart items to existing cart
    if (cartItemsToAdd.length > 0) {
      this.addCartItems(cartItemsToAdd, false);
    }

    // delete old local storage cart
    this.delete(localStorageCart.id, "partitionKey", null, true).subscribe(() => this.localStorageService.set(LocalStorageKeys.CartId, ""));
  }

  updateCartUser(cartId: string, userId: string, accountId: string) {
    let params = new HttpParams();
    params = params.append("paritionKey", "00000000-0000-0000-0000-000000000000");

    const jsonPatchOperation = [
      { op: "replace", path: "/userId", value: userId },
      { op: "replace", path: "/accountId", value: accountId },
    ];

    return this.httpClient.patch<Cart>(`${this.baseUrl}${this.endpoint}/${cartId}`, jsonPatchOperation, {
      params,
    });
  }

  getCartByUserId(userId: string): Observable<SearchResult<Cart>> {
    const searchModel = new CartSearchModel();
    searchModel.userId = userId;
    return this.httpClient.get<SearchResult<Cart>>(`${this.baseUrl}${this.endpoint}`, {
      params: searchModel as any,
    });
  }

  isInventoryItemInCart(inventoryItemId: string): boolean {
    if (!this.cart) {
      return false;
    }

    return this.cart.items.some(d => d.inventoryItem.id === inventoryItemId);
  }

  getItemByInventoryItemId(inventoryItemId: string): CartItem {
    if (!this.cart) {
      return null;
    }

    return this.cart.items.find(d => d.inventoryItem.id === inventoryItemId);
  }

  getCartItemEstimate(cartItem: CartItem): Observable<CartItem> {
    return this.httpClient.post<CartItem>(`${this.baseUrl}${this.endpoint}/00000000-0000-0000-0000-000000000000/item/estimate`, cartItem);
  }

  getOtherAttachmentIds(machineIdToExclude: string): string[] {
    if (this.cart == null || this.cart?.items.length == 0) {
      return [];
    }

    let machineIdsInCart = [];

    for (const item of this.cart.items.filter(d => d.inventoryItem.id != machineIdToExclude)) {
      for (const attachment of item.attachments) {
        machineIdsInCart.push(attachment.childItemId);
      }
    }

    return machineIdsInCart;
  }

  addItemToCart(cartItem: CartItem) {
    if (this.isInventoryItemInCart(cartItem.inventoryItem.id)) {
      return;
    }

    this.logService.trackEvent(MarketTrackingEvents.Cart.TryAddItem, { inventoryId: cartItem.inventoryItem.id });

    if (this.cart == null || this.cart.id === "") {
      this.cart = new Cart();
      // create cart if id is empty
      this.cart.items = [cartItem];
      if (this.user) {
        this.cart.userId = this.user.profile.sub;
        this.cart.accountId = this.user.profile.account_id;
      }

      this.create(this.cart).subscribe(cart => {
        this.updateSubscribers(cart);
        // this.toastrService.success("Item added to your cart", null);
        this.logService.trackEvent(
          MarketTrackingEvents.Cart.ItemAdded,
          {
            event: "ecommerce",
            ecommerceType: "cart",
            ecommerceAction: "Item added",
            inventoryId: cartItem.inventoryItem.id,
          },
          true,
        );

        if (!this.user) {
          this.localStorageService.set(LocalStorageKeys.CartId, cart.id);
        }
      });

      return;
    }

    this.addCartItems([cartItem], true);
  }

  addCartItems(cartItems: CartItem[], showToast: boolean): void {
    let params = new HttpParams();
    if (this.user != null) {
      params = params.append("accountId", this.user.profile.account_id);
    }

    this.httpClient.post(`${this.baseUrl}${this.endpoint}/${this.cart.id}/item`, cartItems, { params }).subscribe((cart: Cart) => {
      this.updateSubscribers(cart);

      if (showToast) {
        this.toastrService.success("Item added to your cart", null);

        // we only want to track it if the user is adding a machine to the cart
        // not when we merge carts. if we are showing toast then we are adding a cart item and not merging
        cartItems
          .map(d => d.inventoryItem.id)
          .forEach((inventoryId: string) =>
            this.logService.trackEvent(
              MarketTrackingEvents.Cart.ItemAdded,
              {
                event: "ecommerce",
                ecommerceType: "cart",
                ecommerceAction: "Item added",
                inventoryId,
              },
              true,
            ),
          );
      }
    });
  }

  updateCartItem(cartItem: CartItem, displayUpdateMessage: boolean = true) {
    let params = new HttpParams();
    if (this.user != null) {
      params = params.append("partitionKey", this.user.profile.account_id);
    }

    // find the index of the cart item
    const index = this.cart.items.findIndex(d => d.id == cartItem.id);
    if (index == -1) {
      return;
    }

    // replace cart item
    this.cart.items[index] = cartItem;
    const jsonPatchOperation = [{ op: "replace", path: "/items", value: this.cart.items }];

    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${this.cart.id}`, jsonPatchOperation, { params }).pipe(
      tap((cart: Cart) => {
        this.updateSubscribers(cart);
        this.logService.pushTagToGtm({
          event: "ecommerce",
          ecommerceType: "cart",
          ecommerceAction: "Item updated",
          inventoryItemId: cartItem?.inventoryItem?.id || "",
        });
      }),
    );
  }

  removeItemFromCart(cartItemId: string): void {
    this.logService.trackEvent(MarketTrackingEvents.Cart.TryRemoveItem);

    const inventoryItemId = this.cart.items.find(d => d.id === cartItemId)?.inventoryItem?.id;

    const removeAction$ =
      this.cart.items.length === 1
        ? this.delete(this.cart.id, "partitionKey", this.user ? this.user.profile.account_id : null, true)
        : this.deleteCartItem(cartItemId);

    removeAction$.subscribe((cart: Cart) => {
      this.updateSubscribers(cart);
      this.toastrService.success("Item removed from your cart", null, { positionClass: "toast-top-center" });
      this.logService.trackEvent(
        MarketTrackingEvents.Cart.ItemRemoved,
        {
          event: "ecommerce",
          ecommerceType: "cart",
          ecommerceAction: "Item removed",
          inventoryItemId,
        },
        true,
      );

      // cart was deleted, clear out localstorage
      if (cart == null) {
        this.localStorageService.set(LocalStorageKeys.CartId, "");
        this.localStorageService.set(LocalStorageKeys.CheckoutTermsAccepted, false);
        this.logService.trackEvent(MarketTrackingEvents.Cart.CartDeleted);
      }
    });
  }

  deleteCartItem(cartItemId: string) {
    let params = new HttpParams();
    if (this.user != null && this.user.profile != null) {
      params = params.append("partitionKey", this.user.profile.account_id);
    }

    const newCartItems = this.cart.items.filter(d => d.id != cartItemId);
    const jsonPatchOperation = [{ op: "replace", path: "/items", value: newCartItems }];

    return this.httpClient.patch(`${this.baseUrl}${this.endpoint}/${this.cart.id}`, jsonPatchOperation, {
      headers: this.getAuth(),
      params,
    });
  }

  addTransportationInfo(transportationInfo: TransportationInfo): Observable<any> {
    let params = new HttpParams();
    params = params.append("partitionKey", this.user.profile.account_id);

    const jsonPatchOperation = [{ op: "replace", path: "/transportationInfo", value: transportationInfo }];

    return this.httpClient
      .patch(`${this.baseUrl}${this.endpoint}/${this.cart.id}`, jsonPatchOperation, { params })
      .pipe(tap((cart: Cart) => this.updateSubscribers(cart)));
  }

  addPaymentInfo(paymentContact: JobsiteContact[], poNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("partitionKey", this.user.profile.account_id);

    const jsonPatchOperation = [
      { op: "replace", path: "/paymentContacts", value: paymentContact },
      { op: "replace", path: "/poNumber", value: poNumber },
    ];

    return this.httpClient
      .patch(`${this.baseUrl}${this.endpoint}/${this.cart.id}`, jsonPatchOperation, { params })
      .pipe(tap((cart: Cart) => this.updateSubscribers(cart)));
  }

  createOrder(paymentContacts: JobsiteContact[], jobNumber: string) {
    return this.httpClient.post<any>(`${this.baseUrl}/api/orders/v2/order`, { cartId: this.cart.id, paymentContacts, jobNumber }, { headers: this.getAuth() });
  }

  completeCart() {
    this.cart.activeStatus = "Inactive";
    this.updateSubscribers(this.cart);
  }

  wipeCart() {
    this.updateSubscribers(new Cart());
  }

  updateSubscribers(cart: Cart) {
    this.cart = cart;
    this.cartBSubject.next(this.cart);
  }
}
