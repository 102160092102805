import { Injectable } from "@angular/core";
import { PaymentMethod, PaymentUsageType } from "./models/payment-method.model";

@Injectable()
export class PaymentUtils {
  static convertExpirationToDate(expiration: string): Date {
    const split = expiration.split("/");
    const month = split[0];
    const year = "20" + split[1];

    return new Date(parseInt(year), parseInt(month) - 1, 1);
  }

  static splitOutEarningsAndPayments(paymentMethods: PaymentMethod[]): { earnings: PaymentMethod[]; payments: PaymentMethod[] } {
    const earnings: PaymentMethod[] = [];
    const payments: PaymentMethod[] = [];

    for (const paymentMethod of paymentMethods) {
      for (const usage of paymentMethod.usages) {
        if (usage.usageType === PaymentUsageType.Earnings) {
          paymentMethod.isDefaultForUsage = usage.isDefault;
          earnings.push(Object.assign({}, paymentMethod));
          continue;
        }

        if (usage.usageType === PaymentUsageType.Payments) {
          paymentMethod.isDefaultForUsage = usage.isDefault;
          payments.push(Object.assign({}, paymentMethod));
          continue;
        }
      }
    }

    return {
      earnings,
      payments,
    };
  }
}
