import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { DatePipe } from "@angular/common";

import { AppComponent } from "./app.component";
import { RoutesModule } from "./routes/routes.module";
import { SharedModule } from "./shared/shared.module";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout/layout.component";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { LayoutModule } from "@rubbl-layout";

declare let environment: any;

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RoutesModule,
    SharedModule,
    FormsModule,
    LayoutModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
    NgxGoogleAnalyticsRouterModule, // https://www.npmjs.com/package/ngx-google-analytics#setup-routing-module
    GoogleTagManagerModule.forRoot({
      id: "GTM-WD8K79F",
    }),
    RouterModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: window.location.host != "localhost:4200",
      registrationStrategy: "registerImmediately",
    }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
