
import { Injectable } from '@angular/core';

@Injectable()

export class DateTimeUtils {

  static verifyDateValue(date: Date | string) {
    if (typeof (date) === 'string') {
      return new Date(date);
    }
    return date;
  }

  static verifyDateValueAndRemoveTime(date: Date | string) {
    if (typeof (date) === 'string') {
      date = new Date(new Date(date));
    }

    // Removing time
    if (date) {
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    return date;
  }

  // duration string is `1 month` `2 months`, etc...
  static addDurationToDate(startDate: Date | string, durationString: string) {
    const date = this.verifyDateValue(startDate);
    const duration = parseInt(durationString.split(" ")[0]);

    return new Date(date.setMonth(date.getMonth() + duration));
  }

  static calcMonthDurationFromDays(numberOfDays: number) {
    return Math.ceil(numberOfDays / 31);
  }
}
