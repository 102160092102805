import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SearchRequest } from "../../models/search-request.model";
import { AzureSuggestsResult } from "../../../azure-search/azure-search";
import { LogService, MarketTrackingEvents } from "../../../core/core";

@Component({
  selector: "common-simple-search",
  templateUrl: "./simple-search.component.html",
  styleUrls: ["./simple-search.component.scss"],
})
export class SimpleSearchComponent {
  searchRequest: SearchRequest = new SearchRequest();

  constructor(private router: Router, private logService: LogService) {}

  suggestionSelected($event: AzureSuggestsResult) {
    this.searchRequest.searchTerm = $event.value;
    this.searchRequest.selectedSuggestion = $event;
    this.search();
  }

  search() {
    this.router.navigate([this.searchRequest.searchResultsUrl()], { queryParams: this.searchRequest.queryParams });
    this.logService.trackEvent(
      MarketTrackingEvents.Search.SimpleSearch,
      {
        event: "rentalSearch",
        searchTerm: this.searchRequest.searchTerm,
        location: this.searchRequest.location.toQueryParam(),
      },
      true,
    );
  }
}
