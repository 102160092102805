import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { CartService } from "../../../cart.service";
import { ModalService } from "../../../../core/core";
import { CartItem } from "../../../models/cart.model";
import { CartItemRemoveConfirmationComponent } from "../../cart-item-remove-confirmation/cart-item-remove-confirmation.component";

@Component({
  selector: "common-add-to-cart-details",
  templateUrl: "./add-to-cart-details.component.html",
  styleUrls: ["./add-to-cart-details.component.scss"],
})
export class AddToCartDetailsComponent implements OnInit, OnChanges {
  @Input() cartItem: CartItem;
  @Input() inCheckout: boolean;
  @Input() onMachineDetails: boolean;
  @Input() isEstimate: boolean;

  @Output() edit = new EventEmitter<CartItem>();
  @Output() addToCart = new EventEmitter<null>();

  rateLabel: string;
  rateSummary: any;
  attachmentRateProperty: string;
  machineRentBeforeDiscount: number;
  discount: number = 0;
  total: number;

  attachmentsUnavailable: string[];

  constructor(private modalService: ModalService, private cartService: CartService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    switch (this.cartItem.duration) {
      case 3:
        this.rateSummary = this.cartItem.dailySummary;
        this.rateLabel = "Daily";
        this.attachmentRateProperty = "dailyPrice";
        break;
      case 7:
      case 14:
        this.rateSummary = this.cartItem.weeklySummary;
        this.rateLabel = "Weekly";
        this.attachmentRateProperty = "weeklyPrice";
        break;

      default:
        this.rateSummary = this.cartItem.monthlySummary;
        this.rateLabel = "Monthly";
        this.attachmentRateProperty = "price";

        const monthlyRentalRate = this.cartItem.charges.find(d => d.name === "Monthly Rental Rate");
        if (monthlyRentalRate) {
          this.machineRentBeforeDiscount = monthlyRentalRate.amountBeforeDiscount;
          this.discount = monthlyRentalRate.discountAmount || 0;
        }

        break;
    }

    let total = 0;
    for (const [key, value] of Object.entries(this.rateSummary)) {
      total += value as number;
    }

    this.total = total;
    this.attachmentsUnavailable = this.cartItem.attachments.filter(d => d.status != "Available").map(d => d.title);
  }

  confirmRemoveItem() {
    const modalRef = this.modalService.standardModal(CartItemRemoveConfirmationComponent, false);
    modalRef.componentInstance.cartItemTitle = this.cartItem.title;

    modalRef.result.then(
      (remove: boolean) => {
        if (remove) {
          this.cartService.removeItemFromCart(this.cartItem.id);
        }
      },
      () => {},
    );
  }

  removeAttachmentFromCartItem() {
    this.cartItem.attachments = this.cartItem.attachments.filter(d => d.status == "Available");
    this.cartService.updateCartItem(this.cartItem).subscribe(() => {});
  }

  removeFromCart() {
    this.cartService.removeItemFromCart(this.cartItem.id);
  }

  editClick() {
    this.edit.emit(this.cartItem);
  }

  addToCartClick() {
    this.addToCart.emit();
  }
}
