import { LayoutComponent } from "../layout/layout.component";

export const routes = [
  {
    path: "welcome",
    loadChildren: () => import("./welcome/welcome.module").then((m) => m.WelcomeModule),
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "info",
        loadChildren: () => import("./info/info.module").then((m) => m.InfoModule),
      },
      {
        path: "",
        loadChildren: () => import("./inspections/inspections.module").then((m) => m.InspectionsModule),
      },
    ],
  },

  // Not found
  { path: "**", redirectTo: "" },
];
