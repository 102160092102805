import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../core';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address, addressType: string = "full", useHtml: boolean = true): any {
    if (!address) {
      return "";
    }

    let str;
    switch (addressType) {
      case "full":
        str = "";
        if (address.address1) {
          str = `${address.address1}`;
        }
        if (address.address2) {
          if(str.length && !useHtml) { str += " " }
          str += address.address2;
        }

        str += `${useHtml === true ? '<br/>' : str.length ? ' ' : '' }${address.city}, ${address.stateProvince}`;

        if (address.postalCode) {
          str += ` ${address.postalCode}`;
        }

        return str;

      case "short":
        str = `${address.city}, ${address.stateProvince}`;
        if (address.postalCode) {
          str += ` ${address.postalCode}`;
        }

        return str;

      case "cityState":
        return `${address.city}, ${address.stateProvince}`;

      default:
        return ""
    }
  }

}
