<ng-template #htmlContent>
  <span [innerHtml]="text"></span>
  <div *ngIf="tableRows">
    <ng-container *ngFor="let tableRow of tableRows; last as isLast">
      <div class="d-flex justify-content-between">
        <!-- only show rows if there is a value -->
        <ng-container *ngIf="tableRow.key">
          <div class="tableRowKey" [innerHtml]="tableRow.key"></div>
          <div class="mr-md-2" [ngClass]="{ 'd-none d-md-block': hideTableValueOnMobile }" [innerHtml]="tableRow.value"></div>
        </ng-container>
      </div>
      <div class="bb-white my-2 mr-md-2" *ngIf="!isLast"></div>
    </ng-container>
  </div>
  &nbsp;
  <span *ngIf="displayLearnMore" class="cursor-pointer text-green bold" (click)="learnMore()">Learn More</span>
  <i class="icon-close text-light-grey" (click)="t.close()"></i>
</ng-template>
<span
  class="m-0 ml-md-1 cursor-pointer"
  [ngbTooltip]="htmlContent"
  autoClose="outside"
  triggers="manual"
  #t="ngbTooltip"
  (click)="t.open()"
  tooltipClass="custom-tooltip"
  [ngClass]="linkText ? linkTextClass : 'icon-question'"
  [placement]="placement"
>
  <span class="link-text cursor-pointer" *ngIf="linkText">{{ linkText }}</span>
</span>
