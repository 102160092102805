import { AuditMetadata } from "../../../core/core";

export class PaymentMethod {
  label: string;
  subLabel: string;
  intuitCustomerId: string;
  intuitId: string;
  expirationDate: string;
  usages: PaymentUsage[];
  accountId: string;
  paymentMethodType: string;
  partitionKey: string;
  auditMetadata: AuditMetadata;
  id: string;
  isActive: boolean;
  isDefaultForUsage: boolean; // this property is computed by the client
}

export enum PaymentMethodType {
  ACH = "ACH",
  CreditCard = "CreditCard",
}

export class PaymentUsage {
  usageType: PaymentUsageType;
  isDefault: boolean;
}

export enum PaymentUsageType {
  Payments = "Payments",
  Earnings = "Earnings",
  Both = "Both",
}
