import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { HttpClientJsonpModule } from "@angular/common/http";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      preventDuplicates: true,
    }),
    HttpClientJsonpModule,
  ],
  exports: [ToastrModule],
})
export class SharedModule {}
