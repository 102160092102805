import { DOCUMENT } from "@angular/common";
import { Injectable, HostListener, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export const WindowSizes = {
  Mobile: "mobile",
  Tablet: "tablet",
  Desktop: "desktop",
  LargeDesktop: "large-desktop",
  ExtraLargeDesktop: "extra-large-desktop",
  ExtraExtraLargeDesktop: "extra-extra-large-desktop",
};

@Injectable({
  providedIn: "root",
})
export class WindowService {
  private innerWidth: any;
  public windowSize: string;

  public windowSizeBSubject = new BehaviorSubject<string>("desktop");

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.innerWidth = window.innerWidth;
    this.determineWindowSize();
    this.windowSizeBSubject.next(this.windowSize);

    window.addEventListener("resize", event => {
      this.onResize(event);
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.determineWindowSize();
    this.windowSizeBSubject.next(this.windowSize);
  }

  determineWindowSize() {
    if (this.innerWidth < 767.98) {
      this.windowSize = WindowSizes.Mobile;
    } else if (this.innerWidth < 991.98) {
      this.windowSize = WindowSizes.Tablet;
    } else if (this.innerWidth < 1200) {
      this.windowSize = WindowSizes.Desktop;
    } else if (this.innerWidth < 1440) {
      this.windowSize = WindowSizes.LargeDesktop;
    } else if (this.innerWidth < 1600) {
      this.windowSize = WindowSizes.ExtraLargeDesktop;
    } else {
      this.windowSize = WindowSizes.ExtraExtraLargeDesktop;
    }
  }

  scrollToElement(querySelector: string, block: ScrollLogicalPosition = "center"): void {
    const element = this.document.querySelector(querySelector) as HTMLElement;

    element.scrollIntoView({
      behavior: "smooth",
      block,
    });

    if (element?.focus) {
      setTimeout(() => element.focus(), 300);
    }
  }
}
